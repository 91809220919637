import { QuantityDTO } from './dto/Check.dto';

export class SplitQuantity {
  n: number;
  d: number;
  w: number;

  static whole(w: number) {
    return new SplitQuantity({ whole: w });
  }

  static fraction(n: number, d: number) {
    return new SplitQuantity({ numerator: n, denominator: d });
  }

  static fromString(str: string): SplitQuantity | null {
    try {
      let whole = 0,
        numerator = 0,
        denominator = 1;
      const parts = str.split(' ');

      if (parts.length === 2) {
        whole = +parts[0];
        const [num, den] = parts[1].split('/');
        numerator = +num;
        denominator = +den;
      } else if (parts[0].includes('/')) {
        const [num, den] = parts[0].split('/');
        numerator = +num;
        denominator = +den;
      } else if (parts[0].includes('.')) {
        const [wholeStr, decimalStr] = parts[0].split('.');
        whole = +wholeStr;
        numerator = +decimalStr;
        denominator = Math.pow(10, decimalStr.length);
      } else {
        whole = +parts[0];
      }

      return new SplitQuantity({ whole, numerator, denominator });
    } catch (e) {
      return null;
    }
  }

  constructor(dto: Partial<QuantityDTO>) {
    this.n = dto.numerator ?? 0;
    this.d = dto.denominator ?? 1;
    this.w = dto.whole ?? 0;

    this.simplify();
  }

  plus(other: SplitQuantity): SplitQuantity {
    // Calculate the least common denominator
    const lcd = this.lcm(this.d, other.d);

    // Convert whole numbers to fractions and add
    const thisNumerator = this.n * (lcd / this.d) + this.w * lcd;
    const otherNumerator = other.n * (lcd / other.d) + other.w * lcd;

    // Add the numerators
    const sumNumerator = thisNumerator + otherNumerator;

    // Create a new SplitQuantity with the sum
    return new SplitQuantity({
      whole: 0,
      numerator: sumNumerator,
      denominator: lcd,
    });
  }

  toString(): string {
    if (this.w === 0 && this.n === 0) return '0';
    if (this.w === 0) return `${this.n}/${this.d}`;
    if (this.n === 0) return `${this.w}`;
    return `${this.w} ${this.n}/${this.d}`;
  }

  toApiInput() {
    if (this.n === 0) {
      return {
        quantity: this.w,
      };
    }

    return {
      quantity_numerator: this.n + this.w * this.d,
      quantity_denominator: this.d,
    };
  }

  fractionEquals(str: string): boolean {
    const other = SplitQuantity.fromString(str);
    if (!other) return false;
    return this.n === other.n && this.d === other.d;
  }

  any(): boolean {
    return this.w > 0 || this.n > 0;
  }

  private gcd(a: number, b: number): number {
    return b === 0 ? a : this.gcd(b, a % b);
  }

  private lcm(a: number, b: number): number {
    return (a * b) / this.gcd(a, b);
  }

  private simplify(): void {
    if (this.d === 0) {
      throw new Error('Denominator cannot be zero');
    }

    // Convert improper fraction to mixed number
    this.w += Math.floor(this.n / this.d);
    this.n %= this.d;

    // Simplify fraction
    const gcd = this.gcd(Math.abs(this.n), this.d);
    this.n /= gcd;
    this.d /= gcd;

    // Ensure positive denominator
    if (this.d < 0) {
      this.n *= -1;
      this.d *= -1;
    }
  }
}

export const EMPTY_QUANTITY = new SplitQuantity({});
