import { Box } from '@mui/material';
import React from 'react';
import { MyChecks } from './components/MyChecks';

export const MyChecksContainer: React.FC = () => {
  return (
    <Box sx={{ p: 2 }}>
      <MyChecks type="all" />
    </Box>
  );
};
