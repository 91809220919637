import { DateTime } from 'luxon';
import { DATE_FORMAT } from '../util/date.util';
import { CheckDTO, CheckItemDTO, CheckItemSplitDTO } from './dto/Check.dto';
import { Restaurant } from './Resutaurant.model';
import { Split } from './Split.model';
import { SplitQuantity } from './SplitQuantity.model';
import { User } from './User.model';

export class Check {
  id: number;
  shareCode: string;
  createdAt: DateTime;
  restaurant: Restaurant;
  isCompleted: boolean;
  subtotal: number;
  tax: number;
  tip: number;
  serviceFee: number;
  totalPrice: number;
  totalClaimedAmount: number;
  totalUnclaimedAmount: number;
  percentClaimed: number;
  owner: User;
  mySplit: Split | null;
  items: CheckItem[];
  splits: Split[];

  private splitsMap: Record<number, Split>;

  constructor(dto: CheckDTO) {
    this.id = dto.id;
    this.shareCode = dto.share_code;
    this.createdAt = DateTime.fromISO(dto.created_at);
    this.restaurant = new Restaurant(dto.restaurant);
    this.isCompleted = dto.is_completed;
    this.subtotal = +dto.subtotal;
    this.tax = +dto.tax;
    this.tip = +dto.tip;
    this.serviceFee = +dto.service_fee;
    this.totalPrice = +dto.total_price;
    this.totalClaimedAmount = +dto.total_claimed_amount;
    this.totalUnclaimedAmount = +dto.total_unclaimed_amount;
    this.percentClaimed = dto.percent_claimed;

    this.owner = new User(dto.owner);
    this.items = dto.check_items.map((item) => new CheckItem(item));
    this.splits = dto.splits.map((split) => new Split(split));
    this.splitsMap = Object.fromEntries(this.splits.map((split) => [split.id, split]));
    this.mySplit = dto.my_split ? new Split(dto.my_split) : null;
  }

  get formattedDate(): string {
    return this.createdAt.toFormat(DATE_FORMAT);
  }

  getSplitById(splitId: number): Split {
    return this.splitsMap[splitId];
  }
}

export class CheckItem {
  id: number;
  name: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  isFullyClaimed: boolean;
  claimedQuantity: SplitQuantity;
  claimedAmount: number;
  unclaimedQuantity: SplitQuantity;
  unclaimedAmount: number;
  splits: CheckItemSplit[];

  constructor(dto: CheckItemDTO) {
    this.id = dto.id;
    this.name = dto.name;
    this.quantity = dto.quantity;
    this.unitPrice = +dto.unit_price;
    this.totalPrice = +dto.total_price;
    this.isFullyClaimed = dto.fully_claimed;
    this.claimedQuantity = new SplitQuantity(dto.claimed_quantity);
    this.claimedAmount = +dto.claimed_amount;
    this.unclaimedQuantity = new SplitQuantity(dto.unclaimed_quantity);
    this.unclaimedAmount = +dto.unclaimed_amount;
    this.splits = dto.splits.map((split) => new CheckItemSplit(split));
  }
}

export class CheckItemSplit {
  splitId: number;
  name: string;
  quantity: SplitQuantity;

  constructor(dto: CheckItemSplitDTO) {
    this.splitId = dto.split_id;
    this.name = dto.name;
    this.quantity = new SplitQuantity(dto.quantity);
  }
}
