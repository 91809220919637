import { Box, Theme, useMediaQuery } from '@mui/material';
import React from 'react';

export const FeedbackContainer: React.FC = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const height = isMobile ? '1750px' : '1500px';

  return (
    <Box sx={{ my: 2 }}>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSdJ0PPQEjz0z3tZtArafLNtFpA3Ux3Cm_19yCImEzA7z-yT0Q/viewform?embedded=true"
        width="100%"
        height={height}
        frameBorder="0"
        marginHeight={0}
        marginWidth={0}
        title="Split It Feedback Survey"
        style={{
          border: 'none',
          minHeight: '100vh', // Ensures iframe is at least full viewport height
        }}
      >
        Loading...
      </iframe>
    </Box>
  );
};
