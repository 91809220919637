import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import React from 'react';
import { SplitCoins } from '../../common/SplitCoins';

const FAQ_DATA = [
  {
    question: <>Is it really free to start using Split It?</>,
    answer: (
      <>
        Yes, you can start using Split It for free with no commitment. You will get 3 free <SplitCoins /> when you sign
        up to get you started.
      </>
    ),
  },
  {
    question: (
      <>
        Can I share the cost of <SplitCoins />?
      </>
    ),
    answer: (
      <>
        Yes, you can split the cost of <SplitCoins /> among everyone involved in the bill. This makes it even more
        affordable to use Split It!
      </>
    ),
  },
  {
    question: <>How does fair splitting work?</>,
    answer: (
      <>
        Split It ensures that each person pays for what they ordered, including tax and tip. Everyone will claim exactly
        what they had, and only pay for their share. Tax, tip, and any service fees are split proportionally based on
        what each person ordered. This way, everyone pays their fair share, and not a penny more.
      </>
    ),
  },
  {
    question: 'How do I get paid back for covering the bill?',
    answer: (
      <>
        Split It auto-populates a Venmo request for everyone with the amount that they owe, making it a breeze to get
        paid instantly at the table. No more waiting for friends to pay you back, and no more tracking down pennies!
      </>
    ),
  },
];

export const FAQSection: React.FC = () => {
  return (
    <Box sx={{ my: 8 }} id="faqs">
      <Typography variant="h4" color="primary" gutterBottom align="center">
        Frequently Asked Questions
      </Typography>
      {FAQ_DATA.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};
