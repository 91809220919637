import { RedirectToSignIn, SignedIn, SignedOut } from '@clerk/clerk-react';
import React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AlertProvider } from './context/useAlertContext';
import { LoadingProvider } from './context/useLoadingContext';
import { AlertContainer } from './features/alert/AlertContainer';
import { CheckContainer } from './features/check/CheckContainer';
import { CheckProvider } from './features/check/hooks/useCheckContext';
import { Loader } from './features/common/Loader';
import { FeedbackContainer } from './features/feedback/FeedbackContainer';
import { HomeContainer } from './features/home/HomeContainer';
import { JoinCheckContainer } from './features/join-check/JoinCheckContainer';
import { MyChecksContainer } from './features/my-checks/MyChecksContainer';
import { NavbarContainer } from './features/navbar/NavbarContainer';
import { NewCheckContainer } from './features/new-check/NewCheckContainer';
import { BuySplitTokens } from './features/split-tokens/BuySplitTokens';
import { MySplitTokensContainer } from './features/split-tokens/MySplitTokensContainer';
import { DashboardContainer } from './features/user-profile/DashboardContainer';
import { ProfileContainer } from './features/user-profile/ProfileContainer';
import { AuthProvider } from './hooks/useAuthContext';
import { MyChecksProvider } from './hooks/useMyChecksContext';
import { MySplitTokensProvider } from './hooks/useMySplitTokensContext';
import { PaymentProvider } from './hooks/useStripeContext';
import { UserProvider } from './hooks/useUserContext';

type Route = {
  path: string;
  component: React.ReactNode;
  signedIn: boolean;
};

const ROUTES: Route[] = [
  { path: '/', component: <HomeContainer />, signedIn: false },
  { path: '/join', component: <JoinCheckContainer />, signedIn: false },
  { path: '/checks/:checkId', component: <CheckContainer />, signedIn: false },
  { path: '/dashboard', component: <DashboardContainer />, signedIn: true },
  { path: '/profile', component: <ProfileContainer />, signedIn: true },
  { path: '/checks', component: <MyChecksContainer />, signedIn: true },
  { path: '/new-check', component: <NewCheckContainer />, signedIn: true },
  { path: '/tokens', component: <MySplitTokensContainer />, signedIn: true },
  { path: '/tokens/buy', component: <BuySplitTokens />, signedIn: true },
  { path: '/feedback', component: <FeedbackContainer />, signedIn: false },
];

export const AppRouter: React.FC = () => {
  return (
    <NonRoutedContextProviders>
      <Router>
        <RoutedContextProviders>
          <Loader />
          <AlertContainer />
          <NavbarContainer />
          <Routes>
            {ROUTES.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  route.signedIn ? (
                    <>
                      <SignedIn>{route.component}</SignedIn>
                      <SignedOut>
                        <RedirectToSignIn
                          signInForceRedirectUrl={window.location.href}
                          signUpForceRedirectUrl={window.location.href}
                        />
                      </SignedOut>
                    </>
                  ) : (
                    route.component
                  )
                }
              />
            ))}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </RoutedContextProviders>
      </Router>
    </NonRoutedContextProviders>
  );
};

const NonRoutedContextProviders: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <AuthProvider>
      <LoadingProvider>
        <AlertProvider>
          <PaymentProvider>
            <UserProvider>
              <MySplitTokensProvider>
                <MyChecksProvider>{children}</MyChecksProvider>
              </MySplitTokensProvider>
            </UserProvider>
          </PaymentProvider>
        </AlertProvider>
      </LoadingProvider>
    </AuthProvider>
  );
};

const RoutedContextProviders: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <CheckProvider>{children}</CheckProvider>;
};
