import { DateTime } from 'luxon';
import { UserDTO } from './dto/User.dto';

export class User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  createdAt: DateTime;
  profileImageUrl: string;
  paymentAccounts: {
    venmo?: { username: string };
  };

  constructor(dto: UserDTO) {
    this.id = dto.id;
    this.firstName = dto.first_name;
    this.lastName = dto.last_name;
    this.email = dto.email;
    this.username = dto.username;
    this.createdAt = DateTime.fromISO(dto.created_at);
    this.paymentAccounts = dto.payment_accounts;
    this.profileImageUrl = dto.profile_image_url;
  }

  get name() {
    return [this.firstName, this.lastName].filter(Boolean).join(' ');
  }

  get venmoUsername() {
    return this.paymentAccounts?.venmo?.username ?? null;
  }

  get memberSince() {
    return this.createdAt.toFormat('LLLL yyyy');
  }
}
