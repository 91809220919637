import { Box, Grid2 as Grid } from '@mui/material';
import React from 'react';
import { useUserContext } from '../../hooks/useUserContext';
import { MyChecks } from '../my-checks/components/MyChecks';
import { UserHeader } from './components/UserHeader';
import { UserStats } from './components/UserStats';
import { VenmoCard } from './components/VenmoCard';

export const DashboardContainer: React.FC = () => {
  const { user } = useUserContext();

  if (!user) {
    return null;
  }

  return (
    <Box sx={{ p: 2 }}>
      <UserHeader />
      <VenmoCard />

      <Grid container spacing={1}>
        <Grid size={{ xs: 12, md: 8 }}>
          <MyChecks type="recent" />
        </Grid>

        <Grid size={{ xs: 12, md: 4 }}>
          {/* <MySplitTokenBalance /> */}
          <UserStats />
        </Grid>
      </Grid>
    </Box>
  );
};
