import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React from 'react';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#343a40',
    },
    secondary: {
      main: '#6c757d',
    },
    background: {
      default: '#e4ecf5', // C4D7CE
      paper: '#ffffff',
    },
    text: {
      primary: '#000000',
    },
    success: {
      main: '#AAF0D1',
      light: '#C5F5E0',
      dark: '#8ADBB7',
    },
    info: {
      main: '#007bff',
    },
    warning: {
      main: '#ffc107',
    },
    error: {
      main: '#ff6b6b',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    h1: {
      fontSize: '60px',
      fontWeight: 700,
    },
    h2: {
      fontSize: '34px',
      fontWeight: 400,
    },
  },
});

export const FULLY_CLAIMED_COLOR = theme.palette.success.main;

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
);

export const useTheme = () => theme;
