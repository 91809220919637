import { ChevronRight, Person } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { $ } from '../../../util/formatCurrency.util';
import { useCheckContext } from '../hooks/useCheckContext';
import { PayButton } from './PayButton';
import { SignUpToSaveSplit } from './SignUpToSaveSplit';

export const CheckSplits: React.FC = () => {
  const { check, setSelectedSplit } = useCheckContext();
  const isMobile = useIsMobile();

  if (!check || !check.mySplit?.id) return null;

  const otherSplits = check.splits.filter((split) => split.id !== check.mySplit?.id);
  const splits = [check.mySplit, ...otherSplits];

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Split Participants
        </Typography>
        <List>
          {splits.map((split) => (
            <React.Fragment key={split.id}>
              <Divider />
              <ListItemButton
                onClick={() => setSelectedSplit(split)}
                key={split.id}
                sx={{ m: 0, p: 0, px: 1.5, display: 'flex', alignItems: 'center' }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: split.color }}>
                    <Person />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      {split.name}
                      {split.id === check.mySplit!.id && (
                        <Typography component="span" variant="caption" sx={{ ml: 1, fontStyle: 'italic' }}>
                          (My Split)
                        </Typography>
                      )}
                    </>
                  }
                  secondary={<>{$(split.totalPrice)}</>}
                />
                <ChevronRight sx={{ ml: 'auto', color: 'action.active' }} />
              </ListItemButton>
            </React.Fragment>
          ))}
        </List>
        {isMobile && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: 2,
              justifyContent: 'flex-end',
              pt: 1,
            }}
          >
            <PayButton includeOwnerMessage={false} />
            <SignUpToSaveSplit />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
