import { Box, Typography } from '@mui/material';
import React from 'react';
import { $ } from '../../../util/formatCurrency.util';

interface ReceiptRowProps {
  title: string;
  amount: number | string;
  isBold?: boolean;
  isMoney?: boolean;
}

export const ReceiptRow: React.FC<ReceiptRowProps> = ({ title, amount, isBold = false, isMoney = true }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
    <Typography variant="body1" fontWeight={isBold ? 'bold' : 'normal'}>
      {title}
    </Typography>
    <Typography variant="body1" fontWeight={isBold ? 'bold' : 'normal'}>
      {isMoney ? $(amount as number) : amount}
    </Typography>
  </Box>
);
