import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';
import { useLoadingContext } from '../../context/useLoadingContext';

export const Loader: React.FC = () => {
  const { isLoading } = useLoadingContext();

  return (
    <Backdrop open={isLoading} sx={(theme) => ({ zIndex: theme.zIndex.modal + 1 })}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
