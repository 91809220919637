import { Container } from '@mui/material';
import React from 'react';
import { FAQSection } from './components/FAQSection';
import { FinalCTASection } from './components/FinalCTASection';
import { HeroSection } from './components/HeroSection';
import { HowItWorksSection } from './components/HowItWorksSection';
import { KeyBenefitsSection } from './components/KeyBenefitsSection';
import { PricingSection } from './components/PricingSection';
import { Footer } from './components/FooterSection';

const comparisonData = [
  { feature: 'Ease of Use', splitit: '★★★★★', others: '★★★☆☆' },
  { feature: 'No Monthly Subscription', splitit: '✓', others: '✗' },
  { feature: 'Pay-per-Use Pricing', splitit: '✓', others: '✗' },
  { feature: 'Split Cost of Service', splitit: '✓', others: '✗' },
];

const testimonials = [
  {
    name: 'Alex R.',
    quote: 'Finally, a fair way to split bills! The AI scanning saved us so much time.',
  },
  {
    name: 'Sarah L.',
    quote: 'Love how tax and tip are split based on what each person ordered.',
  },
  {
    name: 'Mike T.',
    quote: 'Getting paid back instantly is a game-changer. No more waiting!',
  },
];

export const HomeContainer: React.FC = () => {
  return (
    <>
      <Container maxWidth="lg">
        <HeroSection />
        <HowItWorksSection />
        <KeyBenefitsSection />
        <PricingSection />
        {/* <WhyChooseSplitItSection /> */}
        <FAQSection />
        <FinalCTASection />

        {/* <ForOwnersAndFriendsSection /> */}
        {/* <TestimonialsSection /> */}
        {/* <VideoDemoSection /> */}
      </Container>
    </>
  );
};
