import { DateTime } from 'luxon';
import { DATE_FORMAT } from '../util/date.util';
import { CheckSummaryDTO } from './dto/CheckSummary.dto';

export class CheckSummary {
  id: number;
  createdAt: DateTime;
  percentClaimed: number;
  isCompleted: false;
  totalPrice: number;
  restaurantName: string;
  isOwner: false;
  ownerName: string;
  splitCount: number;

  constructor(dto: CheckSummaryDTO) {
    this.id = dto.id;
    this.createdAt = DateTime.fromISO(dto.created_at);
    this.percentClaimed = dto.percent_claimed;
    this.isCompleted = dto.is_completed;
    this.totalPrice = +dto.total_price;
    this.restaurantName = dto.restaurant_name;
    this.isOwner = dto.is_owner;
    this.ownerName = dto.owner_name;
    this.splitCount = dto.split_count;
  }

  get formattedDate(): string {
    return this.createdAt.toFormat(DATE_FORMAT);
  }
}
