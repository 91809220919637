import { SignInButton, SignUpButton } from '@clerk/clerk-react';
import { Person } from '@mui/icons-material';
import { Alert, Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import qs from 'qs';
import React, { useState } from 'react';
import { useUserContext } from '../../../hooks/useUserContext';
import { $ } from '../../../util/formatCurrency.util';
import { VENMO_BLUE, VenmoLogo } from '../../user-profile/components/VenmoCard';
import { useCheckContext } from '../hooks/useCheckContext';

export const PayButton: React.FC<{ includeOwnerMessage?: boolean }> = ({ includeOwnerMessage = true }) => {
  const { check } = useCheckContext();
  const { user } = useUserContext();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (!check || !check.owner || !check.mySplit) return null;

  const isOwner = check.owner.id === check.mySplit?.userId;
  if (isOwner) {
    if (!includeOwnerMessage) {
      return null;
    }

    if (!check.owner.venmoUsername) {
      return (
        <Typography variant="body2" color="text.secondary">
          Be sure to add your Venmo username below so people can pay you back! 💸
        </Typography>
      );
    }

    return (
      <>
        <Alert severity="info">
          People will pay you on Venmo {'  '}
          <a
            href={`https://venmo.com/u/${check.owner.venmoUsername}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: VENMO_BLUE, textDecoration: 'none', fontWeight: 'bold' }}
          >
            @{check.owner.venmoUsername}
          </a>
          . You can update this in your profile.
        </Alert>
      </>
    );
  }

  const amount = check.mySplit?.totalPrice;

  const handleButtonClick = () => {
    if (!user) {
      setIsDialogOpen(true);
    }

    const venmoLink = `https://venmo.com/${check.owner.venmoUsername}?${qs.stringify({
      txn: 'pay',
      amount,
      note: `Split It: Payment for ${check.restaurant.name}`,
    })}`;

    window.open(venmoLink, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      {amount > 0 && !!check.owner.venmoUsername && (
        <Button
          startIcon={<VenmoLogo />}
          sx={{ backgroundColor: VENMO_BLUE }}
          onClick={() => handleButtonClick()}
          variant="contained"
        >
          Pay {check.owner.name} – {$(check.mySplit.totalPrice)}
        </Button>
      )}
      {amount <= 0 && <Alert severity="info">Start claiming items you had to pay {check.owner.name} back.</Alert>}
      {!check.owner.venmoUsername && (
        <>
          <Alert severity="warning">
            Oops! The owner forgot their Venmo username. Give them a nudge to add it to pay them back! 💸
          </Alert>
        </>
      )}
      <Dialog maxWidth="xs" open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogContent sx={{ textAlign: 'center' }}>
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <img src="/splitit-logo.png" alt="Split It Logo" style={{ width: '40px', marginBottom: '8px' }} />
            <Typography variant="h5" sx={{ fontWeight: 500 }}>
              Save Your Split for Later
            </Typography>
          </Box>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
            Create your free account to save this Split and unlock effortless check splitting for all future payments.
          </Typography>
          <SignUpButton forceRedirectUrl={window.location.href} mode="modal">
            <Button
              variant="contained"
              fullWidth
              size="large"
              startIcon={<Person />}
              onClick={() => setIsDialogOpen(false)}
            >
              Create Free Account
            </Button>
          </SignUpButton>
          <SignInButton forceRedirectUrl={window.location.href} mode="modal">
            <Button variant="text" fullWidth size="small" sx={{ mt: 1 }} onClick={() => setIsDialogOpen(false)}>
              Already have an account? Sign in
            </Button>
          </SignInButton>
        </DialogContent>
      </Dialog>
    </>
  );
};
