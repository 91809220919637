import { AlertColor } from '@mui/material';
import React, { createContext, ReactNode, useContext, useState } from 'react';

type AlertMessage = {
  message: string;
  type: AlertColor;
};

interface AlertContextType {
  alert: AlertMessage | null;
  setAlert: (alert: AlertMessage | null) => void;
  setError: (message: string) => void;
  setSuccess: (message: string) => void;
  setInfo: (message: string) => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const AlertProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [alert, setAlert] = useState<AlertMessage | null>(null);

  const setError = (error: string) => setAlert({ message: error, type: 'error' });
  const setSuccess = (message: string) => setAlert({ message, type: 'success' });
  const setInfo = (message: string) => setAlert({ message, type: 'info' });

  return (
    <AlertContext.Provider value={{ alert, setAlert, setError, setSuccess, setInfo }}>{children}</AlertContext.Provider>
  );
};

export const useAlertContext = () => {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error('useAlertContext must be used within an AlertProvider');
  }
  return context;
};
