import { CheckCircleOutline, RadioButtonUnchecked } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { useAlertContext } from '../../../context/useAlertContext';
import { EMPTY_QUANTITY, SplitQuantity } from '../../../models/SplitQuantity.model';
import { FULLY_CLAIMED_COLOR } from '../../../theme';
import { $ } from '../../../util/formatCurrency.util';
import { useCheckContext } from '../hooks/useCheckContext';
import { ReceiptRow } from './ReceiptRow';
import { SplitChips } from './SplitChip';

export const CheckItemModal: React.FC = () => {
  const { check, selectedItem: item, setSelectedItem } = useCheckContext();

  if (!check || !item) return null;

  const onClose = () => setSelectedItem(null);

  return (
    <Dialog open={!!item} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ pb: 0 }}>
        {item.name}
        <Chip
          label={
            item.isFullyClaimed ? 'Fully Claimed' : `${item.claimedQuantity.toString()} of ${item.quantity} claimed`
          }
          size="small"
          icon={item.isFullyClaimed ? <CheckCircleOutline /> : <RadioButtonUnchecked />}
          sx={{
            ml: 1,
            ...(item.isFullyClaimed && { backgroundColor: FULLY_CLAIMED_COLOR }),
          }}
        />
      </DialogTitle>
      <DialogContent sx={{ px: 3, py: 2 }}>
        <Divider sx={{ my: 1 }} />
        <ReceiptRow title="Price:" amount={`${$(item.unitPrice)} x ${item.quantity}`} isMoney={false} />
        <ReceiptRow title="Total:" amount={item.totalPrice} />
        <Divider sx={{ my: 1 }} />
        <SplitChips splits={item.splits} />
        <ClaimQuantityInput onClaimSuccess={onClose} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined" sx={{ minWidth: '100px' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface ClaimQuantityInputProps {
  onClaimSuccess: () => void;
}

const ClaimQuantityInput: React.FC<ClaimQuantityInputProps> = ({ onClaimSuccess }) => {
  const { check, selectedItem: item, claimCheckItemQuantity } = useCheckContext();
  const { setError } = useAlertContext();

  const [showCustomInput, setShowCustomInput] = useState<boolean>(false);
  const [customQuantity, setCustomQuantity] = useState<string>('');

  if (!check || !item) return null;

  const splitCount = check.splits.length;
  const mySplitId = check.mySplit?.id;
  const myQuantity: SplitQuantity =
    item.splits.find((split) => split.splitId === mySplitId)?.quantity ?? EMPTY_QUANTITY;
  const claimableWholeQuantity = item.unclaimedQuantity.plus(myQuantity).w;

  if (item.isFullyClaimed && myQuantity.toString() === '0') {
    return null;
  }

  const claimQuantity = async (quantity: SplitQuantity | null) => {
    if (!quantity) {
      return;
    }

    setCustomQuantity('');
    setShowCustomInput(false);
    await claimCheckItemQuantity(quantity);
    onClaimSuccess();
  };

  const claimCustomQuantity = () => {
    const splitQuantity = SplitQuantity.fromString(customQuantity);
    if (!splitQuantity) {
      setError('Please enter a valid custom quantity (e.g. 1 1/2)');
      return;
    }
    claimQuantity(splitQuantity);
  };

  const handleSelect = (e: SelectChangeEvent) => {
    const value = e.target.value;
    if (value === 'custom') {
      setShowCustomInput(true);
      return;
    }

    if (value === 'remaining') {
      claimQuantity(item.unclaimedQuantity.plus(myQuantity));
      return;
    }

    if (value === 'clear') {
      claimQuantity(SplitQuantity.whole(0));
      return;
    }

    const splitQuantity = SplitQuantity.fromString(value);
    if (splitQuantity) {
      claimQuantity(splitQuantity);
    }
    setShowCustomInput(false);
  };

  return (
    <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <FormControl fullWidth>
        <InputLabel id="claim-select-dropdown">My Claimed Quantity</InputLabel>
        <Select
          labelId="claim-select-dropdown"
          label="My Claimed Quantity"
          value={myQuantity.toString()}
          onChange={handleSelect}
          size="small"
          fullWidth
        >
          {claimableWholeQuantity > 0 && <ListSubheader>Whole Amount</ListSubheader>}
          {Array.from({ length: claimableWholeQuantity + 1 }, (_, i) => i).map((q) => (
            <MenuItem key={q} value={q.toString()}>
              {q === 0 ? 'None' : q}
            </MenuItem>
          ))}
          {splitCount > 1 && <ListSubheader>Partial Amount</ListSubheader>}
          {Array.from({ length: splitCount }, (_, i) => i + 1)
            .filter((denominator) => denominator !== 1)
            .map((denominator) => {
              const fraction = `1/${denominator}`;
              const splitQuantity = SplitQuantity.fromString(fraction);
              if (!splitQuantity) return null;

              return (
                <MenuItem key={fraction} value={fraction}>
                  {fraction}
                </MenuItem>
              );
            })
            .filter(Boolean)}
          <ListSubheader>Other Options</ListSubheader>
          {!item.isFullyClaimed && (
            <MenuItem value="remaining">🏆 Claim Remaining ({item.unclaimedQuantity.toString()})</MenuItem>
          )}
          <MenuItem value="custom">✏️ Claim Custom Quantity</MenuItem>
          {myQuantity.any() && <MenuItem value="clear">🗑️ Clear My Claim</MenuItem>}
        </Select>
        {showCustomInput && (
          <Box sx={{ mt: 2, mb: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
            <TextField
              variant="outlined"
              size="small"
              type="text"
              placeholder="e.g. 2 1/4"
              aria-label="custom quantity input"
              onChange={(e) => setCustomQuantity(e.target.value)}
              fullWidth
            />
            <Button variant="outlined" color="primary" onClick={() => claimCustomQuantity()} sx={{ height: '40px' }}>
              Claim
            </Button>
          </Box>
        )}
      </FormControl>
    </Box>
  );
};
