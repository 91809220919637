import { AutoAwesome, Groups, Payments, Speed } from '@mui/icons-material';
import { Box, Card, CardContent, Grid2 as Grid, Typography } from '@mui/material';
import React from 'react';

const keyBenefits = [
  {
    title: 'AI-Powered Scanning',
    description: 'Items are pre-populated instantly',
    icon: <Speed sx={{ fontSize: 40 }} />,
  },
  {
    title: 'Real-Time Claiming',
    description: 'Friends can join and claim items right at the table',
    icon: <Groups sx={{ fontSize: 40 }} />,
  },
  {
    title: 'Fair and Square Splitting',
    description: 'Only pay for what you ordered',
    icon: <AutoAwesome sx={{ fontSize: 40 }} />,
  },
  {
    title: 'Quick Payback',
    description: 'Get reimbursed immediately',
    icon: <Payments sx={{ fontSize: 40 }} />,
  },
];

export const KeyBenefitsSection: React.FC = () => {
  return (
    <Box sx={{ my: 8 }}>
      <Typography variant="h4" color="primary" gutterBottom align="center" sx={{ mb: 6 }}>
        Why Use Split It?
      </Typography>
      <Card sx={{ maxWidth: '1200px', margin: '0 auto' }}>
        <CardContent>
          <Grid container spacing={4}>
            {keyBenefits.map((benefit, index) => (
              <Grid size={{ xs: 12, sm: 6 }} key={index}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    gap: 3,
                    p: 3,
                    borderRadius: 2,
                    transition: 'all 0.3s ease',
                    bgcolor: '#A5E0C9',
                    height: '100%',
                  }}
                >
                  <Box
                    sx={{
                      color: 'primary.main',
                      display: 'flex',
                      alignItems: 'flex-start',
                      pt: 1,
                    }}
                  >
                    {benefit.icon}
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontWeight: 600,
                        fontSize: '1.25rem',
                      }}
                    >
                      {benefit.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{
                        lineHeight: 1.6,
                      }}
                    >
                      {benefit.description}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
