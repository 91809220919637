import { Box } from '@mui/material';
import React from 'react';
import { MySplitTokenBalance } from './components/MySplitTokenBalance';
import { MySplitTokenOrders } from './components/MySplitTokenOrders';

export const MySplitTokensContainer: React.FC = () => {
  return (
    <Box sx={{ p: 2 }}>
      <MySplitTokenBalance showViewOrdersButton={false} />
      <MySplitTokenOrders />
    </Box>
  );
};
