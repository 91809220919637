import { RestaurantDTO } from './dto/Restaurant.dto';

export class Restaurant {
  id: number;
  name: string;

  constructor(dto: RestaurantDTO) {
    this.id = dto.id;
    this.name = dto.name;
  }
}
