import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useUserContext } from '../../../hooks/useUserContext';

// Venmo colors
export const VENMO_BLUE = '#3D95CE';
export const VENMO_BLUE_DARK = '#1F5B8B';

export const VenmoCard: React.FC = () => {
  const { user } = useUserContext();
  const venmoUsername = user?.venmoUsername;

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {!venmoUsername && (
        <Card sx={{ mb: 1 }}>
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid size={{ xs: 12, sm: 6 }}>
                <Typography variant="h5" gutterBottom>
                  Connect to Venmo
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Easily receive payments for your Splits by connecting your Venmo account.
                </Typography>
              </Grid>
              <Grid
                size={{ xs: 12, sm: 6 }}
                sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'flex-end' } }}
              >
                <Button
                  variant="contained"
                  onClick={() => setIsModalOpen(true)}
                  sx={{
                    bgcolor: VENMO_BLUE,
                    width: { xs: '100%', sm: 'auto' },
                  }}
                  startIcon={<VenmoLogo />}
                >
                  Connect to Venmo
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      <VenmoDialog isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export const VenmoDialog: React.FC<{ isModalOpen: boolean; setIsModalOpen: (open: boolean) => void }> = ({
  isModalOpen,
  setIsModalOpen,
}) => {
  const { user, updateUser } = useUserContext();
  const venmoUsername = user?.venmoUsername;

  const [newUsername, setNewUsername] = useState(venmoUsername);

  const closeModal = () => {
    setIsModalOpen(false);
    setNewUsername(venmoUsername);
  };

  const handleUpdateUsername = () => {
    updateUser({ venmoUsername: newUsername || '' });
    setIsModalOpen(false);
  };

  return (
    <Dialog open={isModalOpen} onClose={closeModal} aria-labelledby="edit-venmo-modal" maxWidth="xs" fullWidth>
      <DialogTitle id="edit-venmo-modal">
        <VenmoLogo fill={VENMO_BLUE} /> Edit Venmo Info
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="My Venmo Username"
          value={newUsername}
          onChange={(e) => setNewUsername(e.target.value)}
          InputProps={{
            startAdornment: <Typography variant="body1">@</Typography>,
          }}
          sx={{ mb: 2, mt: 1 }}
          helperText="Your Venmo username will be used to receive Split It payments from other users."
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Cancel</Button>

        <Button variant="contained" onClick={handleUpdateUsername}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const VenmoLogo: React.FC<{ small?: boolean; fill?: string }> = ({ small = false, fill = 'white' }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fontSize={small ? 'small' : 'medium'}>
      <path
        d="M20.527 0.917969C21.3712 2.30466 21.75 3.73454 21.75 5.54027C21.75 11.2992 16.816 18.7782 12.8115 24.0295H3.66683L0 2.17963L8.00984 1.42188L9.95772 16.9687C11.7679 14.0271 14.0064 9.40482 14.0064 6.25559C14.0064 4.53018 13.7097 3.35718 13.2458 2.39104L20.527 0.917969Z"
        fill={fill}
      ></path>
    </SvgIcon>
  );
};
