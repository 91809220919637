import { ClerkProvider, useAuth } from '@clerk/clerk-react';
import React, { createContext, useContext } from 'react';

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const PUBLISHABLE_KEY =
    process.env.NODE_ENV === 'production'
      ? 'pk_live_Y2xlcmsuc3BsaXRpdC5kZXYk'
      : 'pk_test_d2lyZWQtYWtpdGEtOTEuY2xlcmsuYWNjb3VudHMuZGV2JA';
  if (!PUBLISHABLE_KEY) {
    throw new Error('Missing Publishable Key');
  }

  return (
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <AuthContextProvider>{children}</AuthContextProvider>
    </ClerkProvider>
  );
};

interface AuthContextType {
  isSignedIn: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isSignedIn } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        isSignedIn: isSignedIn ?? false,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
};
