import { DateTime } from 'luxon';
import { SplitTokenOrderDTO, SplitTokenOrderType } from './dto/SplitTokenOrder.dto';
import { SplitTokens } from './SplitTokens.model';

export class SplitTokenOrder {
  id: string;
  createdAt: DateTime;
  type: SplitTokenOrderType;
  tokenQuantity: number;
  totalPaid: number;
  tokens: SplitTokens;

  constructor(dto: SplitTokenOrderDTO) {
    this.id = dto.id;
    this.createdAt = DateTime.fromISO(dto.created_at);
    this.type = dto.type;
    this.tokenQuantity = dto.token_quantity;
    this.totalPaid = dto.amount_total / 100;
    this.tokens = new SplitTokens(dto.tokens);
  }
}
