import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { ApiRequest } from '../common/api-request.type';
import { SplitTokenOrderDTO } from '../models/dto/SplitTokenOrder.dto';
import { SplitTokenOrder } from '../models/SplitTokenOrder.model';
import { SplitTokens } from '../models/SplitTokens.model';
import { useApiCall } from './useApiCall';
import { useAuthContext } from './useAuthContext';
import { useUserContext } from './useUserContext';

interface MySplitTokensContextType {
  mySplitTokens: SplitTokens;
  mySplitTokenOrders: SplitTokenOrder[];

  reloadMySplitTokens: () => Promise<void>;
}

const MySplitTokensContext = createContext<MySplitTokensContextType | undefined>(undefined);

export const MySplitTokensProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const callApi = useApiCall();
  const { user } = useUserContext();

  const [mySplitTokens, setMySplitTokens] = useState<SplitTokens>(SplitTokens.empty());
  const [mySplitTokenOrders, setMySplitTokenOrders] = useState<SplitTokenOrder[]>([]);

  const loadMySplitTokens = async () => {
    if (!user) return;

    const data = await callApi({
      request: ApiRequest.GetMySplitTokens,
      endpoint: '/tokens',
      method: 'GET',
      body: null,
    });

    setMySplitTokens(new SplitTokens(data));
  };

  const loadMySplitTokenOrders = async () => {
    if (!user) return;

    const data = await callApi({
      request: ApiRequest.GetMySplitTokensOrders,
      endpoint: '/tokens/orders',
      method: 'GET',
      body: null,
    });

    setMySplitTokenOrders(data.map((dto: SplitTokenOrderDTO) => new SplitTokenOrder(dto)));
  };

  useEffect(() => {
    (async () => {
      await loadMySplitTokens();
    })();
  }, [user]);

  useEffect(() => {
    (async () => {
      await loadMySplitTokenOrders();
    })();
  }, [user]);

  const reloadMySplitTokens = async () => {
    await loadMySplitTokens();
    await loadMySplitTokenOrders();
  };

  return (
    <MySplitTokensContext.Provider
      value={{
        mySplitTokens,
        mySplitTokenOrders,
        reloadMySplitTokens,
      }}
    >
      {children}
    </MySplitTokensContext.Provider>
  );
};

export const useMySplitTokensContext = () => {
  const context = useContext(MySplitTokensContext);
  if (context === undefined) {
    throw new Error('useMySplitTokensContext must be used within a MySplitTokensProvider');
  }
  return context;
};
