import { ApiRequest } from '../common/api-request.type';
import { useApiCall } from './useApiCall';

export const useParseReceiptApi = () => {
  const callApi = useApiCall();

  const parseReceiptImage = async (formData: FormData): Promise<any> => {
    const data = await callApi({
      request: ApiRequest.ParseReceipt,
      endpoint: '/checks/parse_receipt',
      method: 'POST',
      body: formData,
      isFormData: true,
    });

    return data;
  };

  return { parseReceiptImage };
};
