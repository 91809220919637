import { ReceiptLong } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import { useParseReceiptApi } from '../../../hooks/useParseReceiptApi';
import { NewCheckData, NewCheckDataItem } from '../NewCheckContainer';

interface UploadComponentProps {
  onCheckDataParsed: (checkData: NewCheckData) => void;
}

export const ReceiptUpload: React.FC<UploadComponentProps> = ({ onCheckDataParsed }) => {
  const { parseReceiptImage } = useParseReceiptApi();

  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const resizeImage = (file: File): Promise<Blob> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const maxWidth = 1600;
          const maxHeight = 1600;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx?.drawImage(img, 0, 0, width, height);
          canvas.toBlob(
            (blob) => {
              if (blob) {
                resolve(blob);
              }
            },
            'image/jpeg',
            0.7,
          );
        };
        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleCheckDataParsed = (checkData: NewCheckData) => {
    const combinedItems = checkData.items.reduce((acc, item) => {
      const unitPrice = item.total_price / item.quantity;
      const existingItem = acc.find((i) => i.item_name === item.item_name && i.total_price / i.quantity === unitPrice);
      if (existingItem) {
        existingItem.quantity += item.quantity;
        existingItem.total_price += item.total_price;
      } else {
        acc.push({ ...item });
      }
      return acc;
    }, [] as NewCheckDataItem[]);

    const updatedCheckData = {
      ...checkData,
      items: combinedItems,
    };

    onCheckDataParsed(updatedCheckData);
  };

  const handleImageUpload = useCallback(
    async (file: File) => {
      const resizedImage = await resizeImage(file);
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Image = reader.result as string;
        setPreviewImage(base64Image);
        const formData = new FormData();
        formData.append('receipt_image', base64Image.split(',')[1]);

        const checkData = await parseReceiptImage(formData);
        if (checkData) {
          handleCheckDataParsed(checkData);
        }
      };
      reader.readAsDataURL(resizedImage);
    },
    [parseReceiptImage, onCheckDataParsed],
  );

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      handleImageUpload(event.target.files[0]);
    }
  };

  const handlePaste = useCallback(
    (event: React.ClipboardEvent) => {
      const items = event.clipboardData.items;
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          const file = items[i].getAsFile();
          if (file) {
            handleImageUpload(file);
          }
          break;
        }
      }
    },
    [handleImageUpload],
  );

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (event.dataTransfer.files && event.dataTransfer.files[0]) {
        handleImageUpload(event.dataTransfer.files[0]);
      }
    },
    [handleImageUpload],
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      onPaste={handlePaste}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      tabIndex={0}
      sx={{
        border: '2px dashed #ccc',
        borderRadius: '4px',
        padding: '20px',
        cursor: 'pointer',
        '&:focus': { outline: 'none', borderColor: 'primary.main' },
        '&:hover': { borderColor: 'primary.main' },
      }}
      onClick={() => fileInputRef.current?.click()}
    >
      <input type="file" hidden ref={fileInputRef} accept="image/*,image/heic" onChange={handleFileUpload} />
      <ReceiptLong sx={{ fontSize: 48, color: 'text.secondary' }} />
      <Typography variant="body1" mt={2} align="center">
        Add your receipt here
      </Typography>
      <Typography variant="body2" align="center" sx={{ fontStyle: 'italic' }}>
        Take a photo or upload an image
      </Typography>
      {previewImage && (
        <Box mt={2} sx={{ maxWidth: '100%', maxHeight: '200px', overflow: 'hidden' }}>
          <img src={previewImage} alt="Receipt preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
        </Box>
      )}
    </Box>
  );
};
