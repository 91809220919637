import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useState } from 'react';
import { NewCheckDataItem } from '../NewCheckContainer';
import { EditingItem } from './NewCheckForm';

interface EditItemModalProps {
  editingItem: EditingItem;
  onSave: (updatedItem: NewCheckDataItem) => void;
  onClose: () => void;
}

export const EditNewCheckItemModal: React.FC<EditItemModalProps> = ({ editingItem, onSave, onClose }) => {
  const [itemName, setItemName] = useState(editingItem.item_name);
  const [quantity, setQuantity] = useState(editingItem.quantity);
  const [unitPrice, setUnitPrice] = useState(editingItem.unit_price);
  const [totalPrice, setTotalPrice] = useState(editingItem.total_price);

  const handleChange = (field: keyof EditingItem, value: number | string) => {
    switch (field) {
      case 'item_name':
        setItemName(value as string);
        break;
      case 'quantity':
        const newQuantity = +value;
        setQuantity(newQuantity);
        setTotalPrice(unitPrice * newQuantity);
        break;
      case 'unit_price':
        const newUnitPrice = +value;
        setUnitPrice(newUnitPrice);
        setTotalPrice(newUnitPrice * quantity);
        break;
      case 'total_price':
        const newTotalPrice = +value;
        setTotalPrice(newTotalPrice);
        setUnitPrice(newTotalPrice / quantity);
        break;
    }
  };

  const handleSave = () => {
    onSave({
      item_name: itemName,
      total_price: totalPrice,
      quantity: quantity,
    });
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Edit Item</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Item Name"
          type="text"
          fullWidth
          value={itemName || ''}
          onChange={(e) => handleChange('item_name', e.target.value)}
          sx={{ mb: 1 }}
        />
        <TextField
          autoFocus
          margin="dense"
          label="Quantity"
          type="number"
          fullWidth
          value={quantity || ''}
          onChange={(e) => handleChange('quantity', e.target.value)}
          sx={{ mb: 1 }}
          InputProps={{
            inputProps: { min: 1, inputMode: 'numeric', pattern: '[0-9]*' },
          }}
        />
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            margin="dense"
            label="Unit Price"
            type="number"
            fullWidth
            value={unitPrice || ''}
            onChange={(e) => handleChange('unit_price', e.target.value)}
            InputProps={{
              startAdornment: '$',
              inputProps: { inputMode: 'decimal', pattern: '[0-9]*[.,]?[0-9]*' },
            }}
          />
          <TextField
            margin="dense"
            label="Total Price"
            type="number"
            fullWidth
            value={totalPrice || ''}
            onChange={(e) => handleChange('total_price', e.target.value)}
            InputProps={{
              startAdornment: '$',
              inputProps: { inputMode: 'decimal', pattern: '[0-9]*[.,]?[0-9]*' },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
