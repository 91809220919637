import { ContentCopy, IosShare } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import React, { useState } from 'react';
import { useAlertContext } from '../../../context/useAlertContext';
import { useCheckContext } from '../hooks/useCheckContext';

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://www.splitit.dev' : 'http://localhost:3000';

export const ShareCheckButton: React.FC = () => {
  const { check } = useCheckContext();
  const { setInfo } = useAlertContext();
  const [open, setOpen] = useState(false);

  if (!check) return null;

  const shareUrl = `${BASE_URL}/join?share_code=${check.shareCode}`;

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(`${BASE_URL}/join?share_code=${check.shareCode}`);
    setInfo('Split link copied to clipboard. Send it to your friends!');

    // Maybe re-implement this at some point
    // try {
    //   await navigator.share({
    //     title: `Split the bill for ${check.restaurant.name}`,
    //     text: `${check.owner.name} wants you to split the bill for ${check.restaurant.name} using Split It App.`,
    //     url: `${BASE_URL}/join?share_code=${check.shareCode}`,
    //   });
    // } catch (error) {}
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} startIcon={<IosShare />} color="secondary" variant="contained">
        Share Split
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Share Your Split</DialogTitle>

        <DialogContent>
          <Button fullWidth variant="contained" startIcon={<ContentCopy />} onClick={handleCopyLink}>
            Copy Link
          </Button>
          <Typography variant="body1" align="center" my={2}>
            OR
          </Typography>
          <Typography variant="body1" align="center">
            Share this QR code with your friends to split the bill for {check.restaurant.name}.
          </Typography>
          <Box display="flex" justifyContent="center" my={2}>
            <QRCodeSVG value={shareUrl} size={200} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
