import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useAlertContext } from '../../context/useAlertContext';
import { usePaymentContext } from '../../hooks/useStripeContext';
import { BuySplitTokensCard, WhatAreSplitCoins } from './components/BuySplitTokensCard';

export const BuySplitTokens: React.FC = () => {
  const { getPaymentUrl } = usePaymentContext();
  const { setSuccess, setError } = useAlertContext();

  const [searchParams] = useSearchParams();
  const quantity = searchParams.get('count');
  const status = searchParams.get('status');

  const [paymentUrl, setPaymentUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchPaymentUrl = async () => {
      if (!quantity) {
        return;
      }

      const url = await getPaymentUrl(+quantity);
      if (!url) {
        return;
      }

      setPaymentUrl(url);
    };

    if (!paymentUrl) {
      fetchPaymentUrl();
    }
  }, [quantity, paymentUrl]);

  if (paymentUrl) {
    window.location.href = paymentUrl;
    return null;
  }

  if (!!quantity) {
    return null;
  }

  if (status === 'success') {
    setSuccess('Your SplitCoins purchase was successful! Happy splitting!');
    return <Navigate to="/tokens" />;
  } else if (status === 'cancelled') {
    setError('Your SplitCoins purchase was cancelled.');
  }

  return (
    <Box sx={{ p: 2 }}>
      <BuySplitTokensCard />
    </Box>
  );
};
