import { Add, ListAlt, ShoppingCart } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Grid2 as Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useMySplitTokensContext } from '../../../hooks/useMySplitTokensContext';
import { SplitCoinImage, SplitCoins } from '../../common/SplitCoins';

interface MySplitTokenBalanceProps {
  showNewCheckButton?: boolean;
  showBuyButton?: boolean;
  showViewOrdersButton?: boolean;
}

export const MySplitTokenBalance: React.FC<MySplitTokenBalanceProps> = ({
  showNewCheckButton = true,
  showBuyButton = true,
  showViewOrdersButton = true,
}) => {
  const { mySplitTokens } = useMySplitTokensContext();

  const balance = mySplitTokens.availableTokens;
  const isOutOfTokens = balance === 0;

  return (
    <Card sx={{ mb: 1 }}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid size={{ xs: 6 }}>
            <Box display="flex" alignItems="center">
              <SplitCoinImage />
              <Typography variant="h6" color="text.primary" sx={{ ml: 1 }}>
                My Balance
              </Typography>
            </Box>
          </Grid>
          <Grid size={{ xs: 6 }}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <SplitCoins count={balance} />
            </Box>
          </Grid>
          <Grid size={{ xs: 12 }}>
            {showNewCheckButton && (
              <Button variant="contained" startIcon={<Add />} fullWidth component={Link} to="/new-check" sx={{ mb: 1 }}>
                New Split
              </Button>
            )}
            {showBuyButton && (
              <Button
                variant={!showNewCheckButton ? 'contained' : 'outlined'}
                startIcon={<ShoppingCart />}
                fullWidth
                component={Link}
                to="/tokens/buy"
                sx={{ mb: 1 }}
              >
                Buy SplitCoins
              </Button>
            )}
            {showViewOrdersButton && (
              <Button variant="outlined" startIcon={<ListAlt />} fullWidth component={Link} to="/tokens">
                View All SplitCoins
              </Button>
            )}
          </Grid>
        </Grid>
        {isOutOfTokens && showBuyButton && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1, fontStyle: 'italic', textAlign: 'center' }}>
            Buy SplitCoins to keep splitting with friends!
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
