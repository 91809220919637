import { Card, CardContent, Divider } from '@mui/material';
import React from 'react';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useCheckContext } from '../hooks/useCheckContext';
import { ReceiptRow } from './ReceiptRow';

export const CheckReceipt: React.FC = () => {
  const { check } = useCheckContext();
  const isMobile = useIsMobile();

  if (!check) return null;

  return (
    <Card sx={{ mt: 1 }}>
      <CardContent>
        <Receipt
          subtotal={check.subtotal}
          tax={check.tax}
          tip={check.tip}
          serviceFee={check.serviceFee}
          totalPrice={check.totalPrice}
        />
      </CardContent>
    </Card>
  );
};

interface ReceiptProps {
  subtotal: number;
  tax: number;
  tip: number;
  serviceFee?: number;
  totalPrice: number;
}

export const Receipt: React.FC<ReceiptProps> = ({ subtotal, tax, tip, serviceFee, totalPrice }) => {
  return (
    <>
      <ReceiptRow title="Subtotal" amount={subtotal} isBold={true} />
      <ReceiptRow title="Tax" amount={tax} />
      <ReceiptRow title="Tip" amount={tip} />
      {!!serviceFee && <ReceiptRow title="Service Fee" amount={serviceFee} />}
      <Divider sx={{ my: 2 }} />
      <ReceiptRow title="Total" amount={totalPrice} isBold={true} />
    </>
  );
};
