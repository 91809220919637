import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMySplitTokensContext } from '../../../hooks/useMySplitTokensContext';
import { FULLY_CLAIMED_COLOR } from '../../../theme';
import { $ } from '../../../util/formatCurrency.util';
import { SplitCoinImage } from '../../common/SplitCoins';

export const BuySplitTokensCard: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const tokenOptions = [
    { tokens: 1, price: 2 },
    { tokens: 2, price: 3 },
    { tokens: 5, price: 5, bestValue: true },
  ];

  const { mySplitTokens } = useMySplitTokensContext();

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <Card sx={{ mb: 1 }}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant="h5">Buy SplitCoins</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              color: 'text.secondary',
              fontSize: '0.875rem',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              Balance: <SplitCoinImage large={false} marginLeft={0.5} marginRight={0.5} />
              {mySplitTokens.availableTokens}
            </Box>
            <Typography variant="caption" color="text.secondary">
              <SplitCoinImage large={false} /> 1 SplitCoin per check
            </Typography>
          </Box>
        </Box>
        <Divider />
        <List>
          {tokenOptions.map((tokenBundle) => (
            <React.Fragment key={tokenBundle.tokens}>
              <ListItem
                key={tokenBundle.tokens}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 1,
                  ...(tokenBundle.bestValue && {
                    bgcolor: FULLY_CLAIMED_COLOR, // Light green background for best value
                    borderRadius: 1,
                  }),
                }}
              >
                <ListItemText
                  primary={
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body1">
                        {tokenBundle.tokens} SplitCoin{tokenBundle.tokens > 1 ? 's' : '\u00A0\u00A0'} –
                        <strong> ${tokenBundle.price}</strong>
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ ml: 1, fontSize: '0.75rem' }}>
                        {$(tokenBundle.price / tokenBundle.tokens)} each
                        {tokenBundle.bestValue && (
                          <Chip label="Best Value" color="secondary" size="small" sx={{ ml: 1 }} />
                        )}
                      </Typography>
                    </Box>
                  }
                />
                <Button
                  variant={tokenBundle.bestValue ? 'contained' : 'outlined'}
                  color="primary"
                  component={Link}
                  to={`/tokens/buy?count=${tokenBundle.tokens}`}
                  sx={{ minWidth: '100px', height: '40px', fontSize: '0.875rem' }}
                >
                  Buy {tokenBundle.tokens} <SplitCoinImage />
                </Button>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{
            fontStyle: 'italic',
            textAlign: 'center',
            mb: 2,
          }}
        >
          Don't worry, the cost of a SplitCoin is split between everyone on a check 💸
        </Typography>
        <Button variant="outlined" color="primary" onClick={handleOpenModal} fullWidth>
          What are <SplitCoinImage large={false} marginLeft={0.5} /> SplitCoins?
        </Button>
      </CardContent>
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="what-are-splitcoins-modal"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle>What are SplitCoins?</DialogTitle>
        <DialogContent>
          <WhatAreSplitCoins />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export const WhatAreSplitCoins = () => {
  return (
    <>
      <Typography variant="body1" paragraph>
        SplitCoins are tokens used to split bills with friends. Here's what you need to know:
      </Typography>
      <ol>
        <li>
          Every check you create costs <SplitCoinImage large={false} />1 SplitCoin, no matter how many people split it!
        </li>
        <li>The cost of a SplitCoin is automatically split with everyone who splits the bill.</li>
        <li>SplitCoins are valid forever and can be used for future splits.</li>
      </ol>
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{
          fontStyle: 'italic',
          textAlign: 'center',
        }}
      >
        When your friends pay their share of the bill, they also pay their share of the SplitCoin - making the average
        price to you less than 20¢ per SplitCoin!
      </Typography>
    </>
  );
};
