import { CreditScore, Groups, Paid, Receipt, ReceiptLong, TrendingUp } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useUserContext } from '../../../hooks/useUserContext';
import { $ } from '../../../util/formatCurrency.util';

type UserStat = {
  name: string;
  icon: React.ElementType;
  primary: React.ReactNode;
  secondary: React.ReactNode;
};

export const UserStats: React.FC = () => {
  const { userStats: stats } = useUserContext();

  const [cashBackAmount, setCashBackAmount] = useState(0.04);

  const header = (
    <Typography variant="h6" gutterBottom>
      🚀 Your <strong>Split It</strong> Journey
    </Typography>
  );

  if (!stats || stats.mySplitsCount === 0) {
    return (
      <Card>
        <CardContent>
          {header}
          <Box display="flex" flexDirection="column" alignItems="center" py={4}>
            <Typography variant="h5" gutterBottom>
              🧾
            </Typography>
            <Typography variant="body1" color="text.secondary" align="center">
              Come back once you create a Split to see your lifetime stats!
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  const USER_STATS: UserStat[] = [
    {
      name: 'Splits Created',
      icon: ReceiptLong,
      primary: (
        <>
          Your Splits completed: <strong>{stats.mySplitsCount}</strong>
        </>
      ),
      secondary: `You've successfully created and split ${stats.mySplitsCount} checks. Keep the receipts coming!`,
    },
    {
      name: 'Total Amount on Your Checks',
      icon: Paid,
      primary: (
        <>
          Total split on your checks: <strong>{$(stats.myTotalAmountSplit)}</strong>
        </>
      ),
      secondary: `That's a lot of money effortlessly managed! Splitting checks has never been simpler.`,
    },
    {
      name: 'Average Split Size',
      icon: TrendingUp,
      primary: (
        <>
          Your average check size: <strong>{$(stats.myAverageCheckPrice)}</strong>
        </>
      ),
      secondary: `You've quickly and easily split checks worth $${stats.myAverageCheckPrice} on average!`,
    },
    {
      name: 'Cash Back Earned',
      icon: CreditScore,
      primary: (
        <>
          Cash back earned: <strong>{$(stats.myTotalAmountSplit * cashBackAmount)}</strong>
        </>
      ),
      secondary: `Assuming 4% cash back. Free money is the best money!`,
    },
    {
      name: 'Split It Companions',
      icon: Groups,
      primary: (
        <>
          Split It buddies: <strong>{stats.allTotalSplitsCount}</strong>
        </>
      ),
      secondary: `You've split with ${stats.allTotalSplitsCount} people (including repeat splitters). Your social circle is expanding!`,
    },
    {
      name: 'All Splits',
      icon: Receipt,
      primary: (
        <>
          Total split with friends: <strong>{$(stats.allTotalAmountSplit)}</strong>
        </>
      ),
      secondary: `You've managed to split among friends ${stats.allSplitsCount} times for a total of ${$(
        stats.allTotalAmountSplit,
      )}. Splitting checks has never been this rewarding!`,
    },
  ];

  return (
    <Card>
      <CardContent>
        {header}
        <List>
          {USER_STATS.map((stat) => (
            <ListItem key={stat.icon as string}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: 'primary.main' }}>
                  <stat.icon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={stat.primary} secondary={stat.secondary} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};
