import { ChevronRight, Info } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { $ } from '../../../util/formatCurrency.util';
import { useCheckContext } from '../hooks/useCheckContext';
import { SplitChips } from './SplitChip';

export const CheckItems: React.FC = () => {
  const theme = useTheme();
  const { check, setSelectedItem, selectedItem } = useCheckContext();
  const [lastClearedItemId, setLastClearedItemId] = useState<number | null>(null);
  const prevSelectedItemRef = useRef<typeof selectedItem>(null);

  useEffect(() => {
    if (prevSelectedItemRef.current && !selectedItem) {
      setLastClearedItemId(prevSelectedItemRef.current.id);
      const timer = setTimeout(() => setLastClearedItemId(null), 1000);
      return () => clearTimeout(timer);
    }
    prevSelectedItemRef.current = selectedItem;
  }, [selectedItem]);

  if (!check) return null;

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5">Split Details</Typography>
          <Typography variant="subtitle1" color="text.secondary">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle1" color="text.secondary">
                {check.percentClaimed}% Claimed
              </Typography>
              <Tooltip
                enterTouchDelay={0}
                title={`${$(check.totalClaimedAmount)} / ${$(check.totalPrice)} Claimed`}
                placement="bottom-end"
              >
                <Info sx={{ ml: 1, fontSize: 'large', color: 'text.secondary', cursor: 'pointer' }} />
              </Tooltip>
            </Box>
          </Typography>
        </Box>
        <List>
          {check.items.map((item) => (
            <React.Fragment key={item.id}>
              <Divider />
              <ListItemButton
                sx={{
                  // prettier-ignore
                  backgroundColor: item.id === lastClearedItemId
                    ? (item.isFullyClaimed ? theme.palette.success.dark : '#e3f2fd')
                    : (item.isFullyClaimed ? theme.palette.success.main : 'inherit'),
                  p: 1.5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  '&:hover': {
                    backgroundColor: item.isFullyClaimed ? theme.palette.success.light : 'inherit',
                  },
                  transition: 'background-color 0.3s',
                }}
                onClick={() => setSelectedItem(item)}
              >
                <ListItemText
                  sx={{ p: 0, m: 0, flex: 1 }}
                  primary={
                    <Box sx={{ p: 0, display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="body1">{item.name}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {$(item.unitPrice)} x {item.quantity}
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <Box sx={{ mt: 1 }}>
                      <SplitChips splits={item.splits} />
                    </Box>
                  }
                  secondaryTypographyProps={{ component: 'div' }}
                />
                <ChevronRight sx={{ ml: 1, color: 'action.active' }} />
              </ListItemButton>
            </React.Fragment>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};
