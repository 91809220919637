import { Add as AddIcon, Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid2 as Grid,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useAlertContext } from '../../../context/useAlertContext';
import { useCheckApi } from '../../../hooks/useCheckApi';
import { useMyChecksContext } from '../../../hooks/useMyChecksContext';
import { useMySplitTokensContext } from '../../../hooks/useMySplitTokensContext';
import { $ } from '../../../util/formatCurrency.util';
import { Receipt } from '../../check/components/CheckReceipt';
import { NewCheckData, NewCheckDataItem } from '../NewCheckContainer';
import { EditNewCheckItemModal } from './EditNewCheckItem';

export interface EditingItem extends NewCheckDataItem {
  unit_price: number;
  index: number;
}

interface NewCheckFormProps {
  initialCheckData: NewCheckData;
  isAIGenerated: boolean;
  splitCount: number;
}

export const NewCheckForm: React.FC<NewCheckFormProps> = ({ initialCheckData, isAIGenerated, splitCount }) => {
  const { createCheck } = useCheckApi();
  const { setSuccess } = useAlertContext();
  const { reloadMySplitTokens } = useMySplitTokensContext();
  const { reloadMyChecks } = useMyChecksContext();
  const navigate = useNavigate();

  const [restaurantName, setRestaurantName] = useState(initialCheckData.restaurant_name);
  const [tax, setTax] = useState(initialCheckData.tax);
  const [tip, setTip] = useState(initialCheckData.tip);
  const [serviceFee, setServiceFee] = useState(initialCheckData.service_fee);
  const [items, setItems] = useState<NewCheckDataItem[]>(initialCheckData.items);

  const [newItemInput, setNewItemInput] = useState('');
  const [editingItem, setEditingItem] = useState<EditingItem | null>(null);

  const handleUpdateRestaurantName = (value: string) => {
    setRestaurantName(value);
  };

  const handleUpdateNumericData = (setter: React.Dispatch<React.SetStateAction<number>>, value: string) => {
    if (value === '') {
      setter(0);
      return;
    }
    setter(+value);
  };

  const handleAddItem = () => {
    const quantityRegex = /^\d+/;
    const totalPriceRegex = /\$?\d*(\.\d+)?$/;

    const itemStr = newItemInput.trim();
    const quantityMatch = itemStr.match(quantityRegex);
    const quantity = quantityMatch ? parseInt(quantityMatch[0]) : 1;

    const priceMatch = itemStr.match(totalPriceRegex);
    const totalPrice = priceMatch ? parseFloat(priceMatch[0].replace('$', '')) : 0;

    const name = itemStr.replace(quantityRegex, '').replace(totalPriceRegex, '').trim();

    const newItem: NewCheckDataItem = {
      item_name: name,
      total_price: totalPrice,
      quantity: quantity,
    };

    setItems((prevItems) => [...prevItems, newItem]);
    setNewItemInput('');
  };

  const handleDeleteItem = (index: number) => {
    setItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  const handleEditItem = (item: NewCheckDataItem, index: number) => {
    setEditingItem({
      ...item,
      unit_price: item.total_price / item.quantity,
      index,
    });
  };

  const handleSaveEditedItem = (updatedItem: NewCheckDataItem) => {
    setItems((prevItems) => prevItems.map((item, index) => (index === editingItem?.index ? updatedItem : item)));
    setEditingItem(null);
  };

  const handleCreateSplit = async () => {
    const check = await createCheck({
      restaurant_name: restaurantName,
      tax: tax,
      tip: tip,
      service_fee: serviceFee,
      split_count: splitCount ?? 1,
      items: items.map((item) => ({
        item_name: item.item_name,
        item_price: item.total_price / item.quantity,
        quantity: item.quantity,
      })),
    });

    if (!check) {
      return;
    }

    reloadMyChecks();
    reloadMySplitTokens();
    setSuccess(`Successfully created your Split! Happy splitting!`);
    navigate(`/checks/${check.id}`);
  };

  const calculateSubtotal = () => {
    return items.reduce((sum, item) => sum + item.total_price, 0);
  };

  const calculateTotal = () => {
    return calculateSubtotal() + (tax ?? 0) + (tip ?? 0) + (serviceFee ?? 0);
  };

  return (
    <Grid container spacing={1}>
      <Grid size={{ xs: 12, sm: 4 }}>
        <Card sx={{ mb: 1 }}>
          <CardContent>
            <TextField
              label="Restaurant Name"
              fullWidth
              value={restaurantName || ''}
              onChange={(e) => handleUpdateRestaurantName(e.target.value)}
            />
            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <TextField
                label="Tax"
                type="number"
                fullWidth
                value={tax || ''}
                onChange={(e) => handleUpdateNumericData(setTax, e.target.value)}
                InputProps={{
                  startAdornment: '$',
                  inputProps: { inputMode: 'decimal', pattern: '[0-9]*[.,]?[0-9]*' },
                }}
              />
              <TextField
                label="Tip"
                type="number"
                fullWidth
                value={tip || ''}
                onChange={(e) => handleUpdateNumericData(setTip, e.target.value)}
                InputProps={{
                  startAdornment: '$',
                  inputProps: { inputMode: 'decimal', pattern: '[0-9]*[.,]?[0-9]*' },
                }}
              />
              <TextField
                label="Service Fee"
                type="number"
                fullWidth
                value={serviceFee || ''}
                onChange={(e) => handleUpdateNumericData(setServiceFee, e.target.value)}
                InputProps={{
                  startAdornment: '$',
                  inputProps: { inputMode: 'decimal', pattern: '[0-9]*[.,]?[0-9]*' },
                }}
              />
            </Box>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Receipt
              subtotal={calculateSubtotal()}
              tax={tax}
              tip={tip}
              serviceFee={serviceFee}
              totalPrice={calculateTotal()}
            />
            <Button variant="contained" color="primary" sx={{ mt: 2, width: '100%' }} onClick={handleCreateSplit}>
              Create Split
            </Button>
            {isAIGenerated && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }} align="center">
                AI generated your receipt. 🤖✨
              </Typography>
            )}
            <Typography variant="body2" color="text.secondary" sx={{ my: 1 }} align="center">
              Double-check the Split items — they cannot be changed after creating your Split! 🔒
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid size={{ xs: 12, sm: 8 }}>
        {items.length > 0 && (
          <Card sx={{ mb: 1 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Split Items
              </Typography>
              <List sx={{ width: '100%' }}>
                {items.map((item, index) => (
                  <React.Fragment key={index}>
                    <Divider />
                    <ListItem sx={{ py: 2, px: 0 }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">
                          {item.quantity} x {item.item_name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {$(item.total_price / item.quantity)} each
                        </Typography>
                      </Box>
                      <Typography variant="subtitle1" sx={{ minWidth: 80, textAlign: 'right' }}>
                        {$(item.total_price)}
                      </Typography>
                      <IconButton size="small" sx={{ ml: 1 }} onClick={() => handleEditItem(item, index)}>
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton size="small" onClick={() => handleDeleteItem(index)}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </ListItem>
                  </React.Fragment>
                ))}
              </List>
            </CardContent>
          </Card>
        )}
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Add an item
            </Typography>
            <Typography variant="body2" component="div" color="text.secondary" sx={{ my: 1, mx: 1 }}>
              Examples:
              <List dense sx={{ pl: 1 }}>
                <ListItem sx={{ p: 0 }}>• Nachos $15</ListItem>
                <ListItem sx={{ p: 0 }}>• 2 Burger $24.50</ListItem>
                <ListItem sx={{ p: 0 }}>• 3 Latte $9</ListItem>
              </List>
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                label="[#] Item Name [$]Total"
                fullWidth
                value={newItemInput}
                onChange={(e) => setNewItemInput(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleAddItem()}
              />
              <IconButton color="primary" onClick={handleAddItem} sx={{ ml: 1 }}>
                <AddIcon />
              </IconButton>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      {editingItem && (
        <EditNewCheckItemModal
          editingItem={editingItem}
          onSave={handleSaveEditedItem}
          onClose={() => setEditingItem(null)}
        />
      )}
    </Grid>
  );
};
