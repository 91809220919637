import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { CheckSummary } from '../models/CheckSummary.model';
import { useCheckApi } from './useCheckApi';
import { useUserContext } from './useUserContext';

interface MyChecksContextType {
  myChecks: CheckSummary[];

  reloadMyChecks: () => Promise<void>;
}

const MyChecksContext = createContext<MyChecksContextType | undefined>(undefined);

export const MyChecksProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { fetchMyChecks } = useCheckApi();
  const { user } = useUserContext();

  const [myChecks, setMyChecks] = useState<CheckSummary[]>([]);

  const getMyChecks = async () => {
    if (!user) return;

    const checks = await fetchMyChecks();
    if (!checks) return;

    setMyChecks(checks);
  };

  useEffect(() => {
    getMyChecks();
  }, [user]);

  const reloadMyChecks = async () => {
    await getMyChecks();
  };

  return <MyChecksContext.Provider value={{ myChecks, reloadMyChecks }}>{children}</MyChecksContext.Provider>;
};

export const useMyChecksContext = () => {
  const context = useContext(MyChecksContext);
  if (context === undefined) {
    throw new Error('useMyChecksContext must be used within a MyChecksProvider');
  }
  return context;
};
