import { CalendarMonth, Restaurant } from '@mui/icons-material';
import { Box, Card, CardContent, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useUserContext } from '../../hooks/useUserContext';
import { CheckHelmet } from '../helmet/CheckHelmet';
import { VenmoCard } from '../user-profile/components/VenmoCard';
import { CheckItemModal } from './components/CheckItemModal';
import { CheckItems } from './components/CheckItems';
import { CheckReceipt } from './components/CheckReceipt';
import { CheckSplits } from './components/CheckSplits';
import { PayButton } from './components/PayButton';
import { ShareCheckButton } from './components/ShareCheckButton';
import { SignUpToSaveSplit } from './components/SignUpToSaveSplit';
import { SplitModal } from './components/SplitModal';
import { CheckProvider, useCheckContext } from './hooks/useCheckContext';

export const CheckContainer: React.FC = () => {
  return (
    <CheckProvider>
      <CheckItemModal />
      <SplitModal />
      <CheckComponent />
    </CheckProvider>
  );
};

const CheckComponent: React.FC = () => {
  const { check } = useCheckContext();
  const { user } = useUserContext();
  const isMobile = useIsMobile();

  if (!check) return null;

  return (
    <Box sx={{ p: 2 }}>
      <CheckHelmet check={check} />
      <Card sx={{ mb: 1 }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid size={{ xs: 12, sm: 4 }}>
              <Typography variant="h5" component="div" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Restaurant sx={{ mr: 1 }} />
                {check.restaurant.name}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                <CalendarMonth sx={{ mr: 1 }} />
                {check.formattedDate}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 8 }}>
              <Box
                sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2, justifyContent: 'flex-end' }}
              >
                <PayButton />
                <SignUpToSaveSplit />
                <ShareCheckButton />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {user?.id && check.owner.id === user.id && <VenmoCard />}
      <Grid container spacing={1}>
        {!isMobile && (
          <Grid size={{ xs: 12, md: 4 }}>
            <CheckSplits />
            <CheckReceipt />
          </Grid>
        )}
        <Grid size={{ xs: 12, md: 8 }}>
          <CheckItems />
        </Grid>
        {isMobile && (
          <Grid size={{ xs: 12 }}>
            <CheckSplits />
            <CheckReceipt />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
