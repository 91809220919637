import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import { Footer } from './features/home/components/FooterSection';
import { AppRouter } from './Router';
import { theme } from './theme';

export const App: React.FC = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <AppRouter />
          <Footer />
        </Box>
      </ThemeProvider>
    </React.StrictMode>
  );
};
