import { ApiRequest } from '../common/api-request.type';
import { Check } from '../models/Check.model';
import { CheckSummary } from '../models/CheckSummary.model';
import { CheckSummaryDTO } from '../models/dto/CheckSummary.dto';
import { useApiCall } from './useApiCall';

export type ClaimItemsParams = {
  item_id: number;
  quantity?: number; // Integer
  quantity_numerator?: number; // Integer
  quantity_denominator?: number; // Integer
  split_evenly?: boolean;
};

export type CreateCheckParams = {
  restaurant_name: string;
  tax: number;
  tip: number;
  service_fee: number;
  split_count: number;
  items: {
    item_name: string;
    item_price: number;
    quantity: number;
  }[];
};

export const useCheckApi = () => {
  const callApi = useApiCall();

  const fetchMyChecks = async (): Promise<CheckSummary[]> => {
    const data = await callApi({
      request: ApiRequest.GetMyChecks,
      endpoint: '/checks',
      method: 'GET',
      body: null,
    });

    if (!data) {
      return [];
    }

    return data.map((check: CheckSummaryDTO) => new CheckSummary(check));
  };

  const createCheck = async (checkBody: CreateCheckParams): Promise<Check | null> => {
    const data = await callApi({
      request: ApiRequest.CreateCheck,
      endpoint: '/checks',
      method: 'POST',
      body: JSON.stringify(checkBody),
    });

    if (!data) {
      return null;
    }

    return new Check(data);
  };

  const fetchCheck = async (checkId: string, splitAuthCode?: string): Promise<Check | null> => {
    const data = await callApi({
      request: ApiRequest.GetCheck,
      endpoint: `/checks/${checkId}?split_auth_code=${splitAuthCode || ''}`,
      method: 'GET',
      body: null,
    });

    if (!data) {
      return null;
    }

    return new Check(data);
  };

  const claimCheckItems = async (
    checkId: string,
    items: ClaimItemsParams[],
    splitAuthCode?: string,
  ): Promise<Check | null> => {
    const data = await callApi({
      request: ApiRequest.ClaimItem,
      endpoint: `/checks/${checkId}/items/claim`,
      method: 'PUT',
      body: JSON.stringify({
        items,
        split_auth_code: splitAuthCode,
      }),
    });

    if (!data) {
      return null;
    }

    return new Check(data);
  };

  const fetchCheckByShareCode = async (shareCode: string): Promise<Check | null> => {
    const data = await callApi({
      request: ApiRequest.GetCheckByShareCode,
      endpoint: `/checks/details?share_code=${shareCode}`,
      method: 'GET',
      body: null,
    });

    if (!data) {
      return null;
    }

    return new Check(data);
  };

  const joinCheck = async (
    shareCode: string,
    nickname?: string,
  ): Promise<{ checkId: string; authCode: string } | null> => {
    const data = await callApi({
      request: ApiRequest.JoinCheck,
      endpoint: `/checks/join?share_code=${shareCode}&nickname=${nickname}`,
      method: 'GET',
      body: null,
    });

    return data ? { checkId: data.check_id, authCode: data.auth_code } : null;
  };

  return { fetchMyChecks, fetchCheck, claimCheckItems, fetchCheckByShareCode, joinCheck, createCheck };
};
