import React from 'react';
import { Helmet } from 'react-helmet';
import { Check } from '../../models/Check.model';

type CheckHelmetProps = {
  check: Check;
};

export const CheckHelmet: React.FC<CheckHelmetProps> = ({ check }) => {
  if (!check?.restaurant?.name) return null;

  return (
    <Helmet>
      <title>Split the bill for {check.restaurant.name}</title>
    </Helmet>
  );
};
