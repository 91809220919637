import { UserStatsDTO } from './dto/UserStats.dto';

export class UserStats {
  mySplitsCount: number;
  myTotalSplitsCount: number;
  myTotalAmountSplit: number;
  myAverageCheckPrice: number;

  allSplitsCount: number;
  allTotalSplitsCount: number;
  allTotalAmountSplit: number;
  allAverageCheckPrice: number;

  constructor(dto: UserStatsDTO) {
    this.mySplitsCount = dto.my_splits_count;
    this.myTotalSplitsCount = dto.my_splits_total_splits_count;
    this.myTotalAmountSplit = dto.my_splits_total_amount_split;
    this.myAverageCheckPrice = dto.my_splits_average_check_price;

    this.allSplitsCount = dto.all_splits_count;
    this.allTotalSplitsCount = dto.all_splits_total_splits_count;
    this.allTotalAmountSplit = dto.all_splits_total_amount_split;
    this.allAverageCheckPrice = dto.all_splits_average_check_price;
  }
}
